import React from "react"
import styled from "styled-components"
import ContactUs from "../components/contactUs"
import Layout from "../components/layout"
// import VideoStrip from "../components/videoStrip"
import StyledBackgroundSection from "../components/imageHero"
import { graphql, useStaticQuery } from "gatsby"
import SEO from "../components/seo"
export const Form = styled.form`
  -webkit-appearance: none;
  input,
  textarea {
    -webkit-appearance: none;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19) !important;
    border-radius: 4px;
    margin-bottom: 15px;
    z-index: 20;
  }
`

const Contact = () => {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "header.jpg" }) {
          childImageSharp {
            fluid(quality: 60, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )
  const imageData = data.desktop.childImageSharp.fluid
  return (
    <Layout>
      <SEO
        title={"Connect With Us | Los Angeles California Wedding Film Studio"}
      />
      <div className="">
        <StyledBackgroundSection
          className=""
          imageSrc={imageData}
          title={"Contact Us"}
        />
        <div className="flex flex-col-reverse items-center lg:items-start lg:flex-row max-w-7xl mx-auto lg:space-x-12 px-3">
          <ContactUs isPage={true} />
          <div className="lg:w-3/4 flex flex-col space-y-10 lg:space-y-12 max-w-sm pt-12 lg:pt-32">
            <div>
              We are thrilled that our work has resonated with you! We would be
              delighted to have a conversation with you about your upcoming
              wedding.
            </div>

            <div>
              Should you decide to choose us to capture your big day, we want to
              be right there alongside you, laughing with your friends and
              family, and sharing in the joy of your special day. We believe
              that this is the only way to truly capture authentic and heartfelt
              results.
            </div>

            <div>
              For us, wedding cinematography isn't just about creating beautiful
              moving images. It's about using our 12 years of genuine wedding
              experience to offer you guidance, reassurance, and advice. We
              strive to be the type of person who you're thrilled to have around
              on your wedding day.
            </div>

            <div>
              Please feel free to complete the form below or use the contact
              details provided to get in touch with us. We're excited to hear
              from you and to learn more about your special day!
            </div>
            <div>
              <div>
                Email:{" "}
                <a className="underline" href="mailto:johnmark@jmsdfilms.com">
                  johnmark@jmsdfilms.com
                </a>
              </div>
              <div>
                Call or Text{" "}
                <a className="underline" href="tel:424-287-9002">
                  +1 424-287-9002
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* <ContactUs isPage={true} /> */}

        {/* <VideoStrip /> */}
      </div>
    </Layout>
  )
}

export default Contact
